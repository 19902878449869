.block.generic-text{
	position: relative;
	padding: 50px 30px;

	h1,h2,h3,h4,h5,h6{
		text-align: left;
	}

	article{
		padding-bottom: 30px;
	}

	.tablet({

	});

	.desktop({

	});
}