.block.services{
	position: relative;
	padding: 50px 30px;
	

	h1,h2,h3,h4,h5,h6{
		max-width: 90%;
		margin: auto;
	}

	header{
		padding-bottom: 35px;
	}

	.item{
		position: relative;
		padding-bottom: 15px;
		max-width: 360px;
		margin: auto;

		h1,h2,h3,h4,h5,h6{
			text-transform: none;
			font-weight: bold;
			font-size: 2.8rem;
			padding: 20px 0;
		}

		a{
			display: block;
			position: relative;
		}

		a + div{
			display: block;
			position: absolute;
			left:0;
			right:0;
			top: 80px;
		}
	}

	footer{
		text-align: center;
		a{
			.button;
			background: #464646;
		}
	}

.tablet({
	header{
		text-transform: uppercase;
	}
	
	article{
		position: relative;
		display: flex;
		justify-content: space-between;
	}

	.item{
		width: 24%;

		a + div{
			top: 90px;
		}
	}
});

.desktop({

});
}