.block.gallery{
	position: relative;
	padding: 50px 30px;


	h1,h2,h3,h4,h5,h6{
		text-align: left;
		text-transform: uppercase;
	}

.tablet({
	header{
		padding-bottom: 25px;
	}

	article{
		display: flex;
		flex-wrap: wrap;

		a{
			margin: 0 1%;
			width: 23%;
			background: #000;
			
			img{
				transition: all .3s;
			}

			&:hover{
				img{
					opacity: .6;
				}
			}
		}
	}
});

.desktop({

});
}