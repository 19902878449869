.block.header{
	position: relative;
	color: @secondaryText;
	padding: 225px 20px;
	text-align: center;

	&.slim{
		padding: 175px 20px;
	}

	h1,h2,h3,h4,h5,h6{
		color: @secondaryHeading;
		font-size: 4rem;
		font-weight: bold;
		line-height: 1.5;
		padding-bottom: 25px;
	}

	a{
		.button;
		margin: auto;
	}
	

	.tablet({
		padding: 260px 20px;

		&.slim{
			padding: 140px 20px;
		}
	});

	.desktop({

	});
}