.block.questions{
	position: relative;
	padding: 50px 30px;
	background: @primary;
	color: @secondaryText;
	text-align: left;

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		color: @secondaryHeading;
		padding: 30px 0;
	}

	a{
		color: @secondaryText;
	}

	figure{
		height: 300px;
		width: 300px;
		position: relative;
		overflow: hidden;
		border-radius: 100%;
		object-fit: cover;
		
		img{
			display: block;
		}
	}


.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	header{
		width: 100%;
		padding-bottom: 20px;
	}

	article{
		width: 60%;
	}

	figure{
		width: 27%;
		height: auto;
		img{
			display: block;
		}
	}
});

.desktop({

});
}