.block.contact{
	position: relative;
	padding: 50px 30px;

	aside{
		padding: 25px;
		background: @primary;
		color: @secondaryText;
	}

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		color: @secondaryText;
		font-size: 2rem;
		text-transform: uppercase;
	}

	article{
		margin: auto;
		max-width: 300px;
		padding: 30px 0;

		button[type="submit"]{
			width: 300px;
			padding: 0;
			display: block;
		}
	}

.tablet({
	.inner{
		flex-wrap: wrap;
		display: flex;
		width: 100%;
		align-items: start;
	}

	aside{
		width: 30%;
		padding: 30px;
		box-sizing: border-box;
		order: 2;
	}

	article{
		width: 67%;
		order: 1;
		max-width: 67%;
	}
});

.desktop({

});
}