.block.feature-content{
	position: relative;
	padding: 50px 30px;
	color: @secondaryText;
	text-align: center;
	

	h1,h2,h3,h4,h5,h6{
		max-width: 90%;
		margin: auto;
		padding-bottom: 30px;
		color: @secondaryText;
	}

	article{
		padding: 30px;
		background: rgba(0,0,0,.4);
	}

.tablet({
	padding: 130px 30px;

	article{
		padding: 50px 30px;
	}
});

.desktop({

});
}