.block.about-row{
	position: relative;
	padding: 50px 30px;

	h1,h2,h3,h4,h5,h6{
		text-align: left;
	}

	article{
		padding-bottom: 30px;
	}

.tablet({
	padding: 65px 30px;

	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	header{
		width: 100%;
		padding-bottom: 20px;
	}

	article{
		width: 46.5%;
	}

	figure{
		width: 46.5%;
	}
});

.desktop({

});
}