.block.featured{
	position: relative;
	padding: 50px 30px;
	
	h1,h2,h3,h4,h5,h6{
		max-width: 90%;
		margin: auto;
	}

	header{
		padding-bottom: 35px;
	}

	.item{
		position: relative;
		padding-bottom: 25px;
		text-align: center;

		a{
			.button;
		}

		h1,h2,h3,h4,h5,h6{
			text-transform: none;
			font-weight: bold;
			font-size: 2.8rem;
			padding: 20px 0;
		}

		p:last-of-type{
			padding-top: 30px;
		}
	}

.tablet({
	padding: 80px 30px;

	article{
		display: flex;
		flex-wrap: wrap;
	}

	.item{
		width: 31%;

		&:nth-of-type(2),
		&:nth-of-type(5){
			margin: 0 2.5%;
		}
	}

});

.desktop({

});
}