.block.info{
	position: relative;
	padding: 50px 30px;
	background: @primary;
	color: @secondaryText;
	text-align: center;

	h1,h2,h3,h4,h5,h6{
		color: @secondaryHeading;
		text-transform: uppercase;
	}

	a{
		color: @secondaryText;
	}

.tablet({
	
});

.desktop({

});
}