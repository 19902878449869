.block.text-image{
	position: relative;
	padding: 50px 30px;
	background: @gray-lt;

	article{
		padding-bottom: 30px;
	}

.tablet({
	padding: 65px 30px;

	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	header{
		width: 100%;
		padding-bottom: 20px;
	}

	article{
		width: 46.5%;
	}

	figure{
		width: 46.5%;
	}

	img{
		display: block;
		border:1px solid #fff;
	}
});

.desktop({

});
}