.block.logos{
	position: relative;
	padding: 50px 30px;
	background: @primary;
	color: @secondaryText;

	h1,h2,h3,h4,h5,h6{
		color: @secondaryHeading;
	}

	a{
		color: @secondaryText;
	}

	footer{
		padding-top: 30px;
	}

.tablet({
	h1,h2,h3,h4,h5,h6{
		text-transform: uppercase;
		padding-bottom: 30px;
	}

	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	header{
		width: 100%;
	}

	article{
		width: 75%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.item{
		max-width: 200px;
	}

	footer{
		width: 22%;
	}


});

.desktop({

});
}