.block.gray-row{
	position: relative;
	padding: 50px 30px;

	.inner{
		background: @gray-lt;
		color: #000;
		padding: 25px;
		box-sizing: border-box;
	}

	h1,h2,h3,h4,h5,h6{
		text-align: left;
		color: #000;
		font-weight: bold;
	}

	article{
		padding-bottom: 30px;
	}

.tablet({
	padding: 15px 30px;
});

.desktop({

});
}