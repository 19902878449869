#nav{
	position: relative;
	padding: 15px;

	.inner{
		height: 40px;
	}

	#logo{
		position: relative;
		display: block;
		float: left;
		height: 40px;
		z-index: 2000;

		img{
			margin-top: 3px;
			height: 37px;
			display: block;
		}
	}

	.menu{
		float: right;
		margin-right: 60px;

		ul{
			display: none;
		}

		a{
			color: @text;

			&:hover{
				color: @primary;
			}
		}
	}

	#grip{
		position: absolute;
		top:0;
		right: 0;
		height: 40px;
		width: 40px;
		background: @primary;
		text-align: center;
		z-index: 2000;

		svg{
			width: 25px;
			height: 25px;
			margin-top: 7px;
			color: #fff;
		}
	}

	p{
		font-weight: bold;
		height: 40px;
		line-height: 40px;
	}

.tablet({
	#logo{
		height: 47px;
		width: 180px;
		margin-top: 8px;

		img{
			height: 47px;
			width: 180px;
		}
	}

	.inner{
		height: 65px;
	}

	#grip{
		display: none;
	}

	.menu{
		margin-right: 0;
		p{
			text-align: right;
			height: 30px;
			line-height: 30px;
		}
		ul{
			display: block;
			display: flex;
		}

		li{
			padding-left: 20px;
		}

		a{
			text-transform: uppercase;
		}
	}
});	

.desktop({
	
});	
}