.generic-supernav-overlay{
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index: 1000;
	// backdrop-filter: blur(3px);

	&:before{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content:"";
		background: #fff;
		// opacity: .85;
	}
	
	.primary{
		padding: 90px 20px 0;
		text-align: center;

		.in{
			padding: 50px 20px 0;
		}
		li{
			padding: 5px 0;
		}
		a{
			// color: #fff;
			color: #333;
			font-size: 2.2rem;

			&:hover{
				color:#a91010;
			}
		}
	}


	.secondary{
		padding: 30px 20px 0;
		color: #fff;
	}


	.generic-social{
		padding-bottom: 30px;

		li{
			list-style: none;
			display: inline-block;
			padding-right: 20px;
		}

		a{
			color: #fff;
			font-size: 24px;
			display: block;
			height: 53px;
			line-height: 53px;
			width: 53px;
			border-radius: 100%;
			background: @primary;
			text-align: center;
			padding: 1px;

			&:hover{
				background: #fff;
				color: @text;
			}
		}

		svg{
			display: block;
			height: 25px;
			width: 25px;
			margin: auto;
			margin-top: 14px;
		}

		span{
			display: none;
		}
	}


	.generic-contact{
		padding: 20px;
		font-size: 1.4rem;
		line-height: 1.35;

		strong{
			font-size: 1.6rem;
			font-weight: bold;
			padding-bottom: 10px;
			display: block;
		}

		a{
			color: #fff;

			&:hover{
				color: @primary;
			}
		}

		p + p{
			padding-top: 10px;
		}
	}


	.generic-logo{
		display: none;

		img{
			max-width: 90%;
		}
	}

.tablet({
	display: flex;

	.inner{
		height: 100%;
		display: flex;
	}

	.primary{
		width: 60%;
		display: flex;
		align-items: center;
		font-size: 3rem;

		.in{
			padding: 0;
		}
	}

	.secondary{
		position: relative;
		width: 40%;
		background: @primary;
		display: flex;
		align-items: center;

		&:before{
			position: absolute;
			top:0;
			left: 0;
			right: -10000px;
			bottom:0;
			content:"";
			background: @primary;
		}
	}

	.generic-logo{
		display: block;
	}

	.in{
		position: relative;
		width: 100%;
	}

	.generic-social{

		li{
			padding: 75px 20px 75px 0;
		}

		// li{
		// 	display: block;
		// 	padding-bottom: 10px;
		// 	overflow: hidden;
		// }

		// a{
		// 	float: left;
		// }

		// span{
		// 	height: 55px;
		// 	line-height: 55px;
		// 	padding-left: 10px;
		// 	color: @primary;
		// }
	}

	.generic-contact{
		background: none;
		padding: 0;
		
		a{
			text-decoration: underline;
			color: #fff;
	
			&:hover{
				color: #fff;
				text-decoration: none;
			}
		}
	}
});
}