#footer{
	position: relative;
	background: @gray-dk;	
	color: @secondaryText;
	padding: 50px 25px;

	h6{
		font-size: 1.7rem;
		padding: 0 0 20px 0;
		margin:0;
	}

	p{
		padding-bottom: 20px;
	}

	img{
		display: inline;
		max-height: 45px;
		margin-right: 20px;
	}

	.primary, .secondary, .tertiary{
		padding-bottom: 30px;
	}

	li{
		list-style: none;
	}

	a{
		color: #fff;
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.copyright{
		width: 100%;
		text-align: center;
	}
});	

.desktop({
	
});	
}